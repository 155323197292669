import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Navbar from "../components/Navbar"
import Footer from "../components/Footer"
import SEO from "../components/SEO"

import ProfilesPage from "../components/ProfilesPage"

const Profiles = ({ data }) => {
  const menuItems = [{ name: "Leaderboard", path: "/leaderboard" }]

  return (
    <>
      <SEO title="Profiles" />
      <Navbar isGHP back items={menuItems} topOffset={0} bottomOffset={125} />
      <ProfilesPage data={data} />
      <Footer back />
    </>
  )
}

export const query = graphql`
  query Profiles {
    sisapi {
      profiles {
        edges {
          node {
            id
            familyName
            givenName
            voteCount
            status
            owner {
              cognitoIdentityId
            }
          }
        }
      }
    }
  }
`

Profiles.propTypes = {
  data: PropTypes.object,
}

export default Profiles
