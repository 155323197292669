import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import _ from "lodash"

import Label from "../elements/label"
import media from "../../styles/media"
import PageHeader from "../elements/PageHeader"
import Preview from "./Preview"
import Searchbar from "../elements/Searchbar"

const Container = styled.div`
  background-color: rgba(196, 18, 48, 0.99);
  padding-left: var(--gutter-s);
  padding-bottom: 15vh;
  .intro,
  .profiles,
  .link,
  .pagination {
    margin-right: var(--gutter-s);
  }
  .intro {
    margin-top: 4rem;
    margin-bottom: 4rem;
    p {
      font-size: 1.1rem;
      line-height: 2rem;
    }
  }
  .profiles {
    position: relative;
    display: grid;
    grid-template: repeat(9, auto) / 1fr;
    grid-gap: 2rem 1rem;
  }
  .pagination {
    padding-top: 2rem;
    text-align: center;
  }
  ${media.tablet`
    padding-left: 10vw;
    .intro,
    .link {
      margin-right: 5vw;
    }
    .profiles {
      margin-right: 20vw;
      grid-template: auto auto auto / 1fr 1fr 1fr;
      grid-gap: 2rem 1rem;
    }
    .pagination {
      margin-right: 20vw;
    }
  `}
  ${media.laptop`
    padding-left: 25vw;
    .intro,
    .profiles,
    .link {
      margin-right: 20vw;
    }
    .profiles {
      h1 {
        font-size: 8rem;
      }
    }
  `}
`

const ProfilesPage = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState("")
  const { sisapi } = data
  const approvedProfiles = _.filter(sisapi.profiles.edges, profile => {
    return (
      profile.node.status === "APPROVED" &&
      profile.node.familyName.toLowerCase().includes(searchTerm.toLowerCase())
    )
  })
  const pageLists = _.chunk(approvedProfiles, 9)

  const [pageNumber, setPageNumber] = useState(0)

  const handleSearch = e => {
    setSearchTerm(e.target.value)
    /*
    _.debounce(() => {
      setSearchTerm(e.target.value)
    }, 150)
    */
  }

  const setPrevPage = () => {
    if (pageNumber > 0) {
      setPageNumber(pageNumber - 1)
    }
  }

  const setNextPage = () => {
    if (pageNumber < pageLists.length - 1) {
      setPageNumber(pageNumber + 1)
    }
  }

  const renderIntro = () => {
    if (sisapi.profiles.edges.length > 0) {
      return <p>List of Kaya Bizz Growth Hackers&#39; Profiles</p>
    } else {
      return (
        <p>
          Profiles are currently being approved, be sure to check back soon for
          yours!
        </p>
      )
    }
  }

  const renderProfiles = () => {
    if (sisapi.profiles.edges.length > 0) {
      /* if we have a search term we render search results else we paginate */
      /* just always update the approved profiles array and see */
      const activeList = pageLists[pageNumber]
      if (activeList) {
        return activeList.map(profile => {
          let name = `${profile.node.givenName} ${profile.node.familyName}`
          return (
            <Preview
              key={profile.node.id}
              slug={profile.node.id}
              name={name.substring(0, 20)}
              votes={profile.node.voteCount}
              imgId={profile.node.owner.cognitoIdentityId}
            />
          )
        })
      }
    } else {
      return null
    }
  }

  return (
    <>
      <title>Growth Hacker Profile</title>
      <Container>
        <PageHeader leftIndent />
        <div className="intro">{renderIntro()}</div>
        <Searchbar onHandleSearch={handleSearch} />
        <div className="profiles">{renderProfiles()}</div>
        <div className="pagination">
          {pageNumber > 0 && (
            <Label
              value="&#8592; Prev"
              innerRotate={-1}
              outerRotate={0}
              fontSize={0.9}
              className="mouse-link"
              style={{ marginRight: "0.5rem" }}
              onClick={setPrevPage}
            />
          )}
          {pageNumber < pageLists.length - 1 && (
            <Label
              value="Next &#8594;"
              innerRotate={1}
              outerRotate={0}
              fontSize={0.9}
              className="mouse-link"
              style={{ marginLeft: "0.5rem" }}
              onClick={setNextPage}
            />
          )}
        </div>
      </Container>
    </>
  )
}

ProfilesPage.propTypes = {
  data: PropTypes.object,
}

export default ProfilesPage
