/**
 * @flow
 */
import React from "react"
import styled from "styled-components"

type Props = {
  onHandleSearch: Function,
}

const Container = styled.div`
  position: relative;
  margin-bottom: 4rem;
  svg {
    position: absolute;
    left: 0;
    top: 50%;
    width: 20px;
    height: 20px;
    transform: translate(50%, -50%);
    fill: rgba(0, 0, 0, 0.6);
  }
  input {
    font-family: "AvenirLTStdBlack";
    font-size: 1.25rem;
    color: rgba(0, 0, 0, 0.8);
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 0;
    border: 0px solid;
    padding: 0.75rem 1rem 0.75rem 3rem;
    &:focus {
      outline: none;
    }
    ::-webkit-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
    ::-moz-placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
    :-ms-input-placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
    :-moz-placeholder {
      color: rgba(0, 0, 0, 0.6);
    }
  }
`

const SearchBar = ({ onHandleSearch }: Props) => {
  return (
    <Container>
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path d="M21.172 24l-7.387-7.387c-1.388.874-3.024 1.387-4.785 1.387-4.971 0-9-4.029-9-9s4.029-9 9-9 9 4.029 9 9c0 1.761-.514 3.398-1.387 4.785l7.387 7.387-2.828 2.828zm-12.172-8c3.859 0 7-3.14 7-7s-3.141-7-7-7-7 3.14-7 7 3.141 7 7 7z" />
      </svg>
      <input type="text" placeholder="Search" onChange={onHandleSearch} />
    </Container>
  )
}

export default SearchBar

/*
  profiles provides a string
    to the input
      this string is the value
        whatever is typed in the input changes the value
*/
