/**
 * @flow
 */

import React from "react"
import styled from "styled-components"

import media from "../../../styles/media"

type Props = {
  leftIndent?: boolean,
}

const Container = styled.div`
  padding-left: ${props => (props.leftIndent ? "var(--gutter-l)" : "0")};
  svg {
    enable-background: new 0 0 699.1 192.7;
    .st0 {
      fill: #c41230;
    }
    .st1 {
      fill: #ffffff;
    }
  }
  ${media.tablet`
    /* padding-left: ${props => (props.leftIndent ? "10vw" : "0")}; */
    padding-left: ${props => (props.leftIndent ? "0" : "0")};
  `}
  ${media.laptop`
    /* padding-left: ${props => (props.leftIndent ? "25vw" : "0")}; */
    padding-left: ${props => (props.leftIndent ? "0" : "0")};
  `}
`

const PageHeader = ({ leftIndent }: Props) => {
  return (
    <Container leftIndent={leftIndent}>
      <svg x="0px" y="0px" viewBox="0 0 699.1 192.7">
        <g>
          <path
            d="M184.2,102.5c-6.8,3.5-13.9,6.1-21.3,7.7c-7.4,1.6-14.9,2.4-22.6,2.4c-8.2,0-15.7-1.3-22.6-3.8c-6.9-2.6-12.9-6.2-17.9-11
		c-5-4.8-8.9-10.6-11.7-17.4c-2.8-6.8-4.2-14.4-4.2-22.8c0-8.4,1.4-16,4.2-22.8c2.8-6.8,6.7-12.6,11.7-17.4c5-4.8,11-8.4,17.9-11
		s14.4-3.8,22.6-3.8c8.4,0,16.1,1,23.1,3c7,2,13.2,5.5,18.5,10.4l-16.2,17.7c-3.2-3.2-6.7-5.7-10.6-7.4c-3.9-1.7-8.9-2.5-14.8-2.5
		c-4.9,0-9.4,0.8-13.4,2.5c-4,1.7-7.4,4-10.3,7c-2.9,3-5,6.6-6.6,10.7s-2.3,8.6-2.3,13.4c0,4.9,0.8,9.4,2.3,13.5
		c1.5,4.1,3.7,7.6,6.6,10.6c2.9,3,6.3,5.3,10.3,7c4,1.7,8.4,2.5,13.4,2.5c5,0,9.4-0.6,13-1.7c3.6-1.1,6.6-2.3,8.7-3.5v-17h-18.8
		V47.8h40.9V102.5z"
          />
          <path
            d="M195,2.6h40.9c5.4,0,10.5,0.5,15.3,1.6c4.8,1,9,2.8,12.7,5.2c3.6,2.4,6.5,5.7,8.7,9.8c2.1,4.1,3.2,9.3,3.2,15.4
		c0,7.5-1.9,13.8-5.8,19c-3.9,5.2-9.5,8.5-17,9.9l26.8,44.6h-27.9L229.7,66h-11.5v42.2H195V2.6z M218.3,46.3H232
		c2.1,0,4.3-0.1,6.6-0.2c2.3-0.1,4.4-0.6,6.3-1.3s3.5-1.9,4.7-3.5c1.2-1.6,1.9-3.8,1.9-6.7c0-2.7-0.5-4.8-1.6-6.4
		c-1.1-1.6-2.5-2.8-4.2-3.7c-1.7-0.8-3.6-1.4-5.8-1.7c-2.2-0.3-4.3-0.4-6.4-0.4h-15.2V46.3z"
          />
          <path
            d="M276.6,54.6c0-8.3,1.4-15.8,4.2-22.6c2.8-6.7,6.7-12.5,11.7-17.2c5-4.7,10.9-8.4,17.7-10.9c6.8-2.5,14.3-3.8,22.5-3.8
		c8.1,0,15.6,1.3,22.4,3.8c6.8,2.5,12.8,6.2,17.7,10.9c5,4.7,8.9,10.5,11.7,17.2c2.8,6.8,4.2,14.3,4.2,22.6c0,8.3-1.4,15.8-4.2,22.6
		c-2.8,6.7-6.7,12.5-11.7,17.2c-5,4.7-10.9,8.4-17.7,10.9c-6.8,2.5-14.3,3.8-22.4,3.8c-8.1,0-15.6-1.3-22.5-3.8
		c-6.8-2.5-12.8-6.2-17.7-10.9c-5-4.7-8.9-10.5-11.7-17.2C278,70.4,276.6,62.9,276.6,54.6z M300.4,54.6c0,4.9,0.8,9.4,2.3,13.4
		c1.5,4.1,3.7,7.6,6.5,10.6c2.8,3,6.2,5.3,10.2,7c4,1.7,8.4,2.5,13.3,2.5c4.9,0,9.3-0.8,13.3-2.5c4-1.7,7.4-4,10.2-7
		c2.8-3,5-6.5,6.5-10.6s2.3-8.5,2.3-13.4c0-4.8-0.8-9.2-2.3-13.3s-3.7-7.7-6.5-10.6c-2.8-3-6.2-5.3-10.2-7c-4-1.7-8.4-2.5-13.3-2.5
		c-4.9,0-9.3,0.8-13.3,2.5c-4,1.7-7.4,4-10.2,7s-5,6.5-6.5,10.6C301.1,45.3,300.4,49.8,300.4,54.6z"
          />
          <path
            d="M385.7,0h25.4L428,66.7h0.3L450.4,0h21.7l22,68.4h0.3L512.1,0h23.6l-30.9,103.9H484L460.7,32h-0.3l-23.3,71.9h-20L385.7,0z
		"
          />
          <path d="M554.1,20.2h-29.6V0h82.1v20.2H577v83.6h-22.9V20.2z" />
          <path d="M610.3,1.6h22.5v38.1h43.8V1.6h22.5v102.2h-22.5V59.7h-43.8v44.2h-22.5V1.6z" />
          <path
            d="M163.1,120.3v61c0,1.9-0.4,10.3,5.2,10.3v1.1h-30.6v-1.1c3.1,0,2.9-8.4,2.9-10.3v-28h-28.7v28c0,1.9-0.4,10.3,2.8,10.3v1.1
		H83.9v-1.1c5.8,0,5.3-8.4,5.3-10.3v-61c0-1.2,0.4-10.2-5.3-10.2v-1h30.7v1c-3.6,0-2.8,9-2.8,10.2v31.9h28.7v-31.9
		c0-1.2,0.5-10.2-2.9-10.2v-1h30.6v1C162.9,110.1,163.1,119.1,163.1,120.3z"
          />
          <path
            d="M219.8,105.8h0.6c30.8,70.6,32.9,74.7,32.9,74.7c4.4,9.7,8.5,9.9,11,10.1v1h-42.6v-1c9.7,0,2.4-14.2,2.4-14.2l-3.9-8.4
		h-23.9c-2.4,5.6-0.2,22.6,14.4,22.6v1h-35.1v-1c3.7-0.2,10.2-1.2,19.6-22.6L219.8,105.8z M207.5,139.4l-10.8,27.4h23L207.5,139.4z"
          />
          <path
            d="M339.6,161.3l1.2,23.7c-3.2,2.1-15,5.5-27.5,5.5c-25.1,0-47.4-15.8-47.4-42.4c0-26.5,22.3-42.4,47.4-42.4
		c15.1,0,24.7,3.2,27.5,5.4l-1.2,23.7h-0.9c-3.5-24.3-16.8-28.1-25.4-28.1c-13,0-18.5,9.2-18.5,41.3c0,32.3,5.9,41.3,18.5,41.3
		c8,0,21.4-4.8,25.4-28.2H339.6z"
          />
          <path
            d="M387,106.2v1c-5.2,0-5.9-0.5-5.9,8.1v65.3c0,8,0.5,7.5,5.5,7.5v1.1h-33.4v-1.1c4.9,0,4.8,0.5,4.8-7.5v-65.3
		c0-7.3,0.2-8.1-4.8-8.1v-1H387z M431.8,179.6c0,0-3.5-4.6-31.1-41.5c19.1-20.6,34.4-30.9,41.6-30.9v-1h-38.9v1
		c7.4,0,9.7,16,1.2,25.2l-20,21.2c0,0,5.1,7,11.9,16.6c12.4,17.9,13.8,19,26.3,19h21v-1.1C440.6,187.9,437.9,187.7,431.8,179.6z"
          />
          <path
            d="M523.4,164.3v22.9H455v-1c4.9,0,6.8-7,6.8-15V121c0-7.3-1.6-15.2-6.8-15.2v-0.9h66.9v24h-1.1c0-22.8-32-23-32-23h-4.2v39.2
		h9.5c0.1,0,12.5,0.4,13.8-13.8h1.1V160H508c-1.3-14.1-13.7-13.8-13.8-13.8h-9.5v40h4.2c0,0,33.5,1.3,33.5-21.9H523.4z"
          />
          <path
            d="M625.3,186.2v1H602c-8.3,0-10.9-5.3-13.7-27c-1.5-11.7-15.6-11-15.6-11h-5.5V174c0,11.5,0.5,12.2,5.4,12.2v1h-35v-1
		c5,0,6.1-0.2,6.1-12.2v-57.8c0-7.3-0.9-10.4-6.1-10.4v-0.9h33.2c23.3,0,44.2,2.8,44.2,22.4c0,17.6-16.2,21.2-27.4,21.2
		c13.5,0,20.2,2.9,26,24C616,181.3,622.2,186,625.3,186.2z M587.6,127.2c0-22.4-3-21.3-16.7-21.3h-3.6v42.2h3.6
		C586.1,148.2,587.6,148.4,587.6,127.2z"
          />
          <g>
            <path
              d="M692.7,176.6c0,0-6.3,9.6-26.7,9.6c-22.8,0-31.6-6-31.6-6v-22.6h1.1c1.9,8,12.1,27.5,30.5,27.5c12.6,0,16.1-15.6,2-26.1
			l-18.1-11.4c-16.7-10.5-20.8-24.8-10.8-37.3c0,0,6.5-8.9,25.7-8.9c20.1,0,27.8,5.4,27.8,5.4v19.7h-1.1c-2.4-8.5-8-24.1-26.8-24.1
			c-12.7,0-16.3,14.1-1.1,24.1l18.1,12C697.4,148.8,701.5,163.8,692.7,176.6z"
            />
          </g>
          <g>
            <path
              className="st0"
              d="M42.4,8.9c0.9-0.1,1.7-0.2,2.6-0.3c0.7,0,1.5,0,2.2-0.1c2.6-0.1,5.3-0.1,7.9,0.3c9.1,1.1,17.3,4.4,24.4,10
			c9.9,7.8,16.1,18,18.4,30.4c0.1,0.6,0.2,1.1,0.3,1.7c0.1,1.1,0.2,2.2,0.4,3.3c0,0.6,0,1.2,0,1.7c0,1.7,0.1,3.3,0,5
			c-0.2,3-0.6,6-1.4,8.9c-2.3,9.1-6.8,16.9-13.4,23.4c-5.1,5-11,8.8-17.9,11.1c-2.8,1-5.7,1.8-8.6,2.3c-2.8,0.4-5.5,0.6-8.3,0.6
			c-2.4,0-4.7-0.2-7.1-0.6c-8.3-1.3-15.9-4.5-22.6-9.6C11,90.6,5.2,82.4,2.2,72.2c-0.5-1.7-1-3.4-1.3-5.2c-0.1-0.7-0.3-1.5-0.5-2.2
			c-0.1-1.1-0.2-2.2-0.4-3.3c0-0.2,0-0.3,0-0.5c-0.2-2.4-0.1-4.8,0.1-7.2c0.3-4.2,1.2-8.2,2.5-12.2c5.2-15,17.7-26.3,30.5-30.4
			c2.4-0.8,4.9-1.6,7.4-2C41.2,9.2,41.8,9.1,42.4,8.9z M10.5,66.1c2.2,20.7,20,38.5,43.1,38.5c22.7,0.1,41.7-17.3,43.6-39.9
			C99.1,41.9,83.1,21.6,60.4,18c-5.5-0.9-11-0.6-16.5,0.6c-5.5,1.3-10.5,3.5-15.1,6.7c-4.6,3.2-8.4,7.1-11.4,11.8
			c3.7-4.9,8.3-8.8,13.9-11.6c5.6-2.7,11.4-4,17.6-3.9c6.2,0.1,12,1.7,17.4,4.7c15.4,8.4,23,26.4,18.4,43.3
			c-4.5,16.6-19.9,28.8-38.5,28C29,97.1,13.7,84.4,10.5,66.1z M74.4,65.8c0-0.1,0-0.3,0-0.4c-0.8-7.9-1.7-15.7-2.5-23.6
			c-0.1-0.5-0.2-1-0.3-1.5c-0.6-2-2.4-3.3-4.4-3.3c-2,0-3.8,1.1-4.5,3c-0.4,1-0.4,2-0.3,3c0.8,7.8,1.7,15.6,2.5,23.4
			c0,0.1,0,0.3,0.1,0.4c1-0.1,1.9-0.2,2.9-0.3c-0.4-3.9-0.8-7.8-1.3-11.7c1.2-0.1,2.4-0.3,3.6-0.4c0.4,3.9,0.8,7.8,1.3,11.7
			C72.4,66,73.3,65.9,74.4,65.8z M48.8,68.5c0-0.2,0-0.3,0-0.4c-0.8-7.8-1.7-15.6-2.5-23.4c-0.1-0.5-0.1-0.9-0.3-1.4
			c-0.5-1.8-1.7-3-3.5-3.4c-1.8-0.4-3.4,0.1-4.6,1.5c-1,1.2-1.3,2.7-1.1,4.2c0.8,7.8,1.7,15.7,2.5,23.5c0,0.1,0,0.3,0,0.4
			c1-0.1,1.9-0.2,2.9-0.3c-0.4-3.9-0.8-7.8-1.3-11.7c1.2-0.1,2.4-0.3,3.6-0.4c0.4,3.9,0.8,7.8,1.3,11.7
			C46.9,68.7,47.8,68.6,48.8,68.5z M26.7,70.9c1-0.1,1.9-0.2,2.9-0.3c-0.4-3.7-0.8-7.4-1.2-11.2c0.2,0.2,0.2,0.3,0.3,0.3
			c1.3,1.5,2.6,3,3.8,4.5c0.1,0.2,0.2,0.4,0.3,0.6c0.2,1.6,0.4,3.3,0.5,4.9c0,0.1,0,0.3,0,0.4c1-0.1,1.9-0.2,2.9-0.3
			c0-0.1,0-0.3,0-0.4c-0.2-2-0.4-4.1-0.7-6.1c0-0.2-0.1-0.5-0.3-0.6c-1.8-2.2-3.7-4.4-5.5-6.5c-0.2-0.2-0.2-0.4-0.1-0.6
			c1.3-2.5,2.7-5.1,4-7.6c0.1-0.2,0.2-0.4,0.1-0.6c-0.2-2.1-0.4-4.1-0.7-6.2c0-0.1,0-0.3-0.1-0.4c-1,0.1-1.9,0.2-2.9,0.3
			c0,0.2,0,0.3,0,0.4c0.2,1.6,0.4,3.3,0.5,4.9c0,0.2,0,0.4-0.1,0.6c-0.9,1.8-1.9,3.6-2.8,5.3c0,0.1-0.1,0.2-0.2,0.4
			c-0.4-3.8-0.8-7.5-1.2-11.2c-1,0.1-1.9,0.2-2.9,0.3C24.6,51.5,25.6,61.2,26.7,70.9z M58.4,67.5c0-0.2,0-0.4-0.1-0.5
			c-0.5-4.7-1-9.4-1.5-14.1c0-0.3,0-0.6,0.2-0.9c0.8-1.6,1.7-3.2,2.5-4.8c0.1-0.2,0.1-0.4,0.1-0.6c-0.3-2.7-0.6-5.4-0.9-8.2
			c0-0.1,0-0.2,0-0.4c-1,0.1-1.9,0.2-2.9,0.3c0,0.2,0,0.3,0.1,0.5c0.2,2.2,0.5,4.4,0.7,6.6c0,0.1,0,0.3,0,0.4c-0.5,1-1.1,2-1.6,3.1
			c-0.1-0.1-0.2-0.2-0.3-0.3c-0.6-0.7-1.2-1.4-1.8-2.1c-0.2-0.2-0.3-0.5-0.3-0.7c-0.3-2.2-0.5-4.4-0.7-6.6c0-0.1,0-0.3,0-0.4
			c-1,0.1-1.9,0.2-2.9,0.3c0,0.1,0,0.2,0,0.2c0.3,2.8,0.6,5.6,0.9,8.4c0,0.2,0.1,0.4,0.2,0.5c1.2,1.4,2.4,2.8,3.5,4.3
			c0.1,0.2,0.3,0.4,0.3,0.7c0.5,4.7,1,9.5,1.5,14.2c0,0.2,0,0.3,0.1,0.5C56.5,67.7,57.4,67.6,58.4,67.5z M37.7,76.7
			c0.1,0.1,0.1,0.1,0.1,0.1c0.5,1.2,1,2.4,1.5,3.7c0.1,0.2,0.2,0.3,0.4,0.2c0.4-0.1,0.8-0.1,1.3-0.2c0.1,0,0.2-0.2,0.3-0.3
			c0.1-0.3,0.1-0.7,0.2-1c0.2-1,0.4-2,0.5-3.1c0,0,0,0,0,0c0.1,1.4,0.3,2.8,0.4,4.2c0.8-0.1,1.5-0.2,2.2-0.2
			c-0.2-2.3-0.5-4.5-0.7-6.7c-0.9,0.1-1.8,0.2-2.7,0.3c-0.2,0-0.3,0.1-0.4,0.4c-0.3,1.3-0.6,2.7-0.8,4c0-0.1-0.1-0.1-0.1-0.2
			c-0.5-1.2-1-2.4-1.6-3.6c-0.1-0.2-0.2-0.3-0.5-0.3c-0.5,0.1-0.9,0.1-1.4,0.2c-0.5,0-0.9,0.1-1.3,0.1c0.2,2.3,0.5,4.5,0.7,6.7
			c0.8-0.1,1.5-0.2,2.3-0.2C38,79.5,37.9,78.1,37.7,76.7z M68.8,75.9c0.2,0.7,0.7,1.1,1.3,1.4c0.7,0.3,1.5,0.3,2.3,0.2
			c1.7-0.3,2.8-1.6,2.7-3.4c0-0.3,0-0.6-0.1-0.9c-0.2-1.1-0.7-2-1.7-2.3c-1.3-0.5-2.7-0.3-3.9,0.4c-0.9,0.5-1.2,1.4-1,2.4
			c0.2,0.9,0.9,1.5,1.9,1.6c0.9,0.1,1.8,0,2.6-0.6c0,0.3,0,0.5-0.1,0.7c-0.2,0.9-1.2,1.2-1.9,0.6c-0.1-0.1-0.2-0.1-0.3-0.1
			C70.1,75.7,69.5,75.8,68.8,75.9z M46.8,78.3c0.3,0.8,0.8,1.2,1.5,1.4c0.7,0.3,1.5,0.2,2.3,0.1c1.4-0.3,2.4-1.3,2.5-2.7
			c0.1-0.5,0-1.1-0.1-1.6c-0.2-1.2-0.8-2.1-2-2.4c-1.2-0.3-2.3-0.2-3.4,0.4c-1,0.5-1.4,1.4-1.2,2.4c0.2,0.9,0.9,1.5,1.9,1.6
			c0.9,0.1,1.8,0,2.5-0.6c0,0.1,0,0.1,0,0.2c0,0.5-0.1,1-0.6,1.3c-0.5,0.2-1,0.2-1.4-0.2c0,0-0.1-0.1-0.2-0.1
			C48.1,78.1,47.4,78.2,46.8,78.3z M54.9,77.3c0.3,0.8,0.9,1.2,1.6,1.4c1.3,0.3,2.6,0.2,3.8-0.4c0.9-0.5,1.3-1.4,1.1-2.4
			c-0.2-1-0.9-1.5-1.9-1.7c-0.8-0.1-1.6,0.1-2.4,0.4c0-0.3,0.1-0.6,0.1-0.9c1.1-0.1,2.2-0.2,3.3-0.4c-0.1-0.5-0.1-0.9-0.2-1.4
			c0-0.1-0.2-0.2-0.3-0.2c-1.5,0.1-2.9,0.3-4.4,0.5c-0.1,0-0.2,0.2-0.2,0.3c-0.1,0.5-0.1,0.9-0.1,1.4c-0.1,0.7-0.1,1.4-0.2,2.1
			c0.1,0,0.1,0,0.2,0c0.5,0,0.9,0,1.4,0c0.3,0,0.5-0.1,0.8-0.2c0.3-0.2,0.6-0.2,1-0.2c0.3,0,0.6,0.2,0.7,0.6
			c0.1,0.3,0.1,0.6-0.2,0.9c-0.3,0.4-1.4,0.5-1.7,0c-0.1-0.1-0.2-0.2-0.3-0.1C56.3,77.1,55.6,77.2,54.9,77.3z M32.7,77.4
			c-0.9,0.1-1.8,0.2-2.7,0.3c0-0.3-0.1-0.7-0.1-1c1.1-0.1,2.3-0.2,3.4-0.4c-0.1-0.6-0.1-1.1-0.2-1.7c-2,0.2-3.9,0.4-5.9,0.6
			c0.2,2.3,0.5,4.5,0.7,6.7c0.8-0.1,1.7-0.2,2.5-0.3c-0.1-0.8-0.2-1.6-0.3-2.4c0.9-0.1,1.8-0.2,2.7-0.3C32.8,78.5,32.7,78,32.7,77.4
			z"
            />
            <circle cx="48.5" cy="59.8" r="38.1" />
            <path
              className="st1"
              d="M10.5,66.1C13.7,84.4,29,97.1,46.3,97.8c18.5,0.8,33.9-11.4,38.5-28c4.6-17-3-34.9-18.4-43.3
			c-5.4-3-11.3-4.6-17.4-4.7c-6.2-0.1-12.1,1.1-17.6,3.9c-5.6,2.7-10.2,6.6-13.9,11.6c3-4.7,6.9-8.6,11.4-11.8
			c4.6-3.2,9.6-5.4,15.1-6.7c5.5-1.3,11-1.5,16.5-0.6c22.7,3.5,38.7,23.8,36.8,46.8c-1.9,22.6-20.8,40-43.6,39.9
			C30.5,104.6,12.7,86.9,10.5,66.1z"
            />
            <path
              className="st1"
              d="M74.4,65.8c-1,0.1-2,0.2-2.9,0.3c-0.4-3.9-0.8-7.8-1.3-11.7c-1.2,0.1-2.4,0.3-3.6,0.4
			c0.4,3.9,0.8,7.8,1.3,11.7c-1,0.1-1.9,0.2-2.9,0.3c0-0.1,0-0.3-0.1-0.4C64,58.6,63.2,50.8,62.3,43c-0.1-1-0.1-2,0.3-3
			c0.7-1.8,2.5-3,4.5-3c2,0,3.7,1.3,4.4,3.3c0.2,0.5,0.3,1,0.3,1.5c0.9,7.9,1.7,15.7,2.5,23.6C74.3,65.5,74.3,65.6,74.4,65.8z
			 M69.8,51.5c0-0.1,0-0.3,0-0.4c-0.3-3.1-0.7-6.1-1-9.2c0-0.2,0-0.3-0.1-0.5C68.6,40.6,67.8,40,67,40c-0.9,0-1.6,0.6-1.8,1.4
			c-0.1,0.3,0,0.7,0,1c0.3,3,0.6,6,1,9c0,0.1,0,0.3,0.1,0.4C67.4,51.7,68.6,51.6,69.8,51.5z"
            />
            <path
              className="st1"
              d="M48.8,68.5c-1,0.1-1.9,0.2-2.9,0.3c-0.4-3.9-0.8-7.8-1.3-11.7c-1.2,0.1-2.4,0.3-3.6,0.4
			c0.4,3.9,0.8,7.8,1.3,11.7c-1,0.1-1.9,0.2-2.9,0.3c0-0.1,0-0.3,0-0.4c-0.8-7.8-1.7-15.7-2.5-23.5c-0.2-1.5,0.1-3,1.1-4.2
			c1.2-1.4,2.8-1.9,4.6-1.5c1.9,0.4,3,1.6,3.5,3.4c0.1,0.4,0.2,0.9,0.3,1.4c0.8,7.8,1.7,15.6,2.5,23.4
			C48.8,68.2,48.8,68.4,48.8,68.5z M44.4,54.2c0-0.2,0-0.3,0-0.5c-0.3-3-0.6-6-1-8.9c0-0.2,0-0.4-0.1-0.5c-0.2-0.9-0.9-1.6-1.8-1.5
			c-0.9,0-1.6,0.6-1.8,1.6c0,0.3,0,0.6,0,0.9c0.3,3,0.6,6,1,9c0,0.1,0,0.3,0,0.4C42,54.4,43.1,54.3,44.4,54.2z"
            />
            <path
              className="st1"
              d="M26.7,70.9c-1-9.7-2.1-19.4-3.1-29.1c1-0.1,1.9-0.2,2.9-0.3c0.4,3.7,0.8,7.4,1.2,11.2
			c0.1-0.2,0.2-0.3,0.2-0.4c1-1.8,1.9-3.5,2.8-5.3c0.1-0.2,0.1-0.4,0.1-0.6c-0.2-1.6-0.3-3.3-0.5-4.9c0-0.1,0-0.3,0-0.4
			c1-0.1,1.9-0.2,2.9-0.3c0,0.1,0,0.3,0.1,0.4c0.2,2.1,0.4,4.1,0.7,6.2c0,0.2,0,0.5-0.1,0.6c-1.3,2.5-2.7,5.1-4,7.6
			c-0.1,0.3-0.1,0.4,0.1,0.6c1.8,2.2,3.7,4.4,5.5,6.5c0.1,0.2,0.2,0.4,0.3,0.6c0.2,2,0.4,4.1,0.7,6.1c0,0.1,0,0.3,0,0.4
			c-1,0.1-1.9,0.2-2.9,0.3c0-0.1,0-0.3,0-0.4c-0.2-1.6-0.3-3.3-0.5-4.9c0-0.2-0.1-0.5-0.3-0.6c-1.3-1.5-2.6-3-3.8-4.5
			c-0.1-0.1-0.2-0.2-0.3-0.3c0.4,3.8,0.8,7.5,1.2,11.2C28.6,70.7,27.6,70.8,26.7,70.9z"
            />
            <path
              className="st1"
              d="M58.4,67.5c-1,0.1-1.9,0.2-2.9,0.3c0-0.2,0-0.3-0.1-0.5c-0.5-4.7-1-9.5-1.5-14.2c0-0.2-0.1-0.5-0.3-0.7
			c-1.2-1.4-2.4-2.8-3.5-4.3c-0.1-0.1-0.2-0.3-0.2-0.5c-0.3-2.8-0.6-5.6-0.9-8.4c0-0.1,0-0.1,0-0.2c1-0.1,1.9-0.2,2.9-0.3
			c0,0.1,0,0.3,0,0.4c0.2,2.2,0.5,4.4,0.7,6.6c0,0.3,0.2,0.5,0.3,0.7c0.6,0.7,1.2,1.4,1.8,2.1c0.1,0.1,0.2,0.2,0.3,0.3
			c0.6-1,1.1-2.1,1.6-3.1c0.1-0.1,0-0.3,0-0.4c-0.2-2.2-0.5-4.4-0.7-6.6c0-0.2,0-0.3-0.1-0.5c1-0.1,1.9-0.2,2.9-0.3
			c0,0.1,0,0.2,0,0.4c0.3,2.7,0.6,5.4,0.9,8.2c0,0.2,0,0.4-0.1,0.6c-0.8,1.6-1.6,3.2-2.5,4.8c-0.2,0.3-0.2,0.6-0.2,0.9
			c0.5,4.7,1,9.4,1.5,14.1C58.4,67.1,58.4,67.3,58.4,67.5z"
            />
            <path
              className="st1"
              d="M37.7,76.7c0.2,1.4,0.3,2.8,0.5,4.2c-0.8,0.1-1.5,0.2-2.3,0.2c-0.2-2.2-0.5-4.4-0.7-6.7
			c0.4,0,0.9-0.1,1.3-0.1c0.5,0,0.9-0.1,1.4-0.2c0.3,0,0.4,0,0.5,0.3c0.5,1.2,1,2.4,1.6,3.6c0,0.1,0.1,0.1,0.1,0.2
			c0.3-1.4,0.6-2.7,0.8-4c0-0.2,0.1-0.3,0.4-0.4c0.9-0.1,1.8-0.2,2.7-0.3c0.2,2.2,0.5,4.5,0.7,6.7c-0.7,0.1-1.5,0.2-2.2,0.2
			c-0.2-1.4-0.3-2.8-0.4-4.2c0,0,0,0,0,0c-0.2,1-0.4,2-0.5,3.1c-0.1,0.4-0.1,0.7-0.2,1c0,0.1-0.2,0.2-0.3,0.3
			c-0.4,0.1-0.8,0.1-1.3,0.2c-0.2,0-0.3,0-0.4-0.2c-0.5-1.2-1-2.4-1.5-3.7C37.8,76.8,37.8,76.8,37.7,76.7z"
            />
            <path
              className="st1"
              d="M68.8,75.9c0.7-0.1,1.3-0.1,1.9-0.2c0.1,0,0.2,0,0.3,0.1c0.7,0.6,1.7,0.3,1.9-0.6c0-0.2,0-0.4,0.1-0.7
			c-0.8,0.6-1.7,0.7-2.6,0.6c-1-0.1-1.7-0.7-1.9-1.6c-0.2-1,0.1-1.8,1-2.4c1.2-0.7,2.6-0.9,3.9-0.4c1.1,0.4,1.5,1.3,1.7,2.3
			c0.1,0.3,0.1,0.6,0.1,0.9c0.1,1.7-1,3.1-2.7,3.4c-0.8,0.1-1.5,0.1-2.3-0.2C69.5,77,69.1,76.6,68.8,75.9z M71.7,73.8
			c0.6-0.1,1-0.4,0.9-1c-0.1-0.5-0.4-0.8-1.1-0.8c-0.6,0.1-1,0.4-1,0.9C70.7,73.5,71.1,73.8,71.7,73.8z"
            />
            <path
              className="st1"
              d="M46.8,78.3c0.7-0.1,1.3-0.1,1.9-0.2c0.1,0,0.2,0,0.2,0.1c0.4,0.4,0.9,0.4,1.4,0.2c0.5-0.3,0.6-0.8,0.6-1.3
			c0,0,0-0.1,0-0.2c-0.8,0.6-1.6,0.7-2.5,0.6c-1.1-0.1-1.8-0.7-1.9-1.6c-0.2-1,0.2-1.9,1.2-2.4c1.1-0.6,2.2-0.7,3.4-0.4
			c1.2,0.3,1.8,1.2,2,2.4c0.1,0.5,0.1,1.1,0.1,1.6c-0.1,1.4-1.1,2.4-2.5,2.7c-0.8,0.2-1.5,0.2-2.3-0.1C47.6,79.4,47.1,79,46.8,78.3z
			 M49.5,74.4c-0.6,0.1-0.9,0.4-0.9,1c0.1,0.6,0.5,0.8,1.1,0.8c0.6-0.1,1-0.5,0.9-1C50.6,74.6,50.1,74.3,49.5,74.4z"
            />
            <path
              className="st1"
              d="M54.9,77.3c0.7-0.1,1.4-0.3,2-0.4c0.1,0,0.2,0.1,0.3,0.1c0.4,0.5,1.4,0.3,1.7,0c0.2-0.3,0.2-0.6,0.2-0.9
			c-0.1-0.3-0.4-0.6-0.7-0.6c-0.3,0-0.7,0-1,0.2c-0.3,0.2-0.5,0.2-0.8,0.2c-0.5,0-0.9,0-1.4,0c0,0-0.1,0-0.2,0
			c0.1-0.7,0.1-1.4,0.2-2.1c0-0.5,0.1-0.9,0.1-1.4c0-0.1,0.1-0.2,0.2-0.3c1.5-0.2,2.9-0.3,4.4-0.5c0.1,0,0.2,0.1,0.3,0.2
			c0.1,0.4,0.1,0.9,0.2,1.4c-1.1,0.1-2.2,0.2-3.3,0.4c0,0.3-0.1,0.6-0.1,0.9c0.8-0.4,1.6-0.5,2.4-0.4c1.1,0.1,1.7,0.7,1.9,1.7
			c0.2,1-0.2,1.9-1.1,2.4c-1.2,0.6-2.5,0.8-3.8,0.4C55.8,78.6,55.2,78.1,54.9,77.3z"
            />
            <path
              className="st1"
              d="M32.7,77.4c0.1,0.6,0.1,1.1,0.2,1.6c-0.9,0.1-1.8,0.2-2.7,0.3c0.1,0.8,0.2,1.6,0.3,2.4
			c-0.9,0.1-1.7,0.2-2.5,0.3c-0.2-2.2-0.5-4.5-0.7-6.7c2-0.2,3.9-0.4,5.9-0.6c0.1,0.5,0.1,1.1,0.2,1.7c-1.1,0.1-2.2,0.2-3.4,0.4
			c0,0.4,0.1,0.7,0.1,1C30.9,77.6,31.7,77.5,32.7,77.4z"
            />
            <path
              d="M69.8,51.5c-1.2,0.1-2.4,0.3-3.6,0.4c0-0.1,0-0.3-0.1-0.4c-0.3-3-0.7-6-1-9c0-0.3,0-0.7,0-1c0.2-0.9,0.9-1.4,1.8-1.4
			c0.8,0,1.6,0.6,1.7,1.5c0,0.1,0,0.3,0.1,0.5c0.3,3.1,0.7,6.1,1,9.2C69.8,51.2,69.8,51.3,69.8,51.5z"
            />
            <path
              d="M44.4,54.2c-1.2,0.1-2.4,0.3-3.6,0.4c0-0.2,0-0.3,0-0.4c-0.3-3-0.6-6-1-9c0-0.3,0-0.6,0-0.9c0.1-0.9,0.8-1.6,1.8-1.6
			c0.9,0,1.6,0.6,1.8,1.5c0,0.2,0,0.4,0.1,0.5c0.3,3,0.6,6,1,8.9C44.3,53.9,44.3,54,44.4,54.2z"
            />
            <path d="M71.7,73.8c-0.6,0.1-1-0.2-1.1-0.8c0-0.5,0.3-0.9,1-0.9c0.6-0.1,1,0.2,1.1,0.8C72.7,73.3,72.3,73.7,71.7,73.8z" />
            <path d="M49.5,74.4c0.6-0.1,1.1,0.2,1.1,0.7c0.1,0.5-0.3,0.9-0.9,1c-0.6,0.1-1-0.2-1.1-0.8C48.6,74.8,48.9,74.5,49.5,74.4z" />
            <circle className="st0" cx="64.9" cy="74.8" r="1.2" />
          </g>
        </g>
      </svg>
    </Container>
  )
}

export default PageHeader
