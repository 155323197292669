/**
 * @flow
 */

import { Auth } from "aws-amplify"
import React, { useState } from "react"
import { Link } from "gatsby"
import { Link as ScrollLink } from "react-scroll"
import styled from "styled-components"
import { navigate } from "gatsby"
import { isMobile } from "react-device-detect"
import { useDispatch, useSelector } from "react-redux"

import { setUser } from "../../state"
import media from "../../styles/media"

type Props = {
  items?: Array<Object>,
  isGHP?: boolean,
  back?: boolean,
  topOffset?: number,
  bottomOffset?: number,
}

const ScrollArea = styled.div`
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 998;
  transition: opacity 150ms ease-out;
  opacity: ${props => (props.mobileActive ? `1` : `0`)};
  pointer-events: none;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${media.tablet`
    display: inline-block;
    background-color: transparent;
    width: auto;
    position: absolute;
    top: ${props => `${props.topOffset}vh`};
    opacity: 1;
    left: 0;
    z-index: 99;
    height: ${props =>
      `calc(100% - ${props.topOffset}vh - ${props.bottomOffset}vh)`};
  `}
`

const MenuIcon = styled.div`
  position: fixed;
  z-index: 999;
  top: 25px;
  right: 10px;
  /* background-color: rgba(255, 0, 0, 0.75); */
  pointer-events: all;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-content: center;
  svg {
    fill: white;
  }
  ${media.tablet`
    display: none;
  `}
`

const Menu = styled.ul`
  pointer-events: all;
  visibility: ${props => (props.mobileActive ? `visible` : `hidden`)};
  ${media.tablet`
    position: sticky;
    top: 0;
    padding: 100px 0 0 2vw;
    opacity: 1;
    visibility: visible;
  `}
`

const MenuLink = styled.li`
  list-style: none;
  text-align: center;
  margin-bottom: 1rem;
  a,
  span {
    display: inline-block;
    position: relative;
    font-size: 2.25rem;
    color: rgba(255, 255, 255, 0.85);
  }
  a {
    font-family: "AvenirLTStdBlack";
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &.small {
    a,
    span {
      font-size: 1.25rem;
      padding-top: 1rem;
    }
  }
  ${media.tablet`
    text-align: left;
    overflow: hidden;
    padding-right: 30px;
    margin-bottom: 0;
    a,
    span {
      font-size: 2.25rem;
      cursor: pointer;
    }
    a {
      transition: 250ms ease-out;
      &:after {
        content: "";
        position: absolute;
        width: calc(100% + 30px);
        height: 2px;
        background-color: rgba(255, 255, 255, 0.85);
        bottom: 0;
        left: -30px;
      }
      &:before {
        content: "→";
        font-family: "Galano Grotesque";
        line-height: 2rem;
        position: absolute;
        left: 0;
        top: 50%;
        width: 30px;
        height: 30px;
        transform: translate(-30px, -50%);
      }
      &.active {
        transform: translate(30px, 0);
      }
    }
    span {
      font-family: "Galano Grotesque";
    }
  `}
`

const Navbar = ({ isGHP, back, items, topOffset, bottomOffset }: Props) => {
  const [mobileActive, setMobileActive] = useState(false)
  const dispatch = useDispatch()

  const user = useSelector(state => state.auth.user)

  const handleSignOut = async () => {
    await Auth.signOut()
    dispatch(setUser({ hasUser: false }))
    navigate("/sign-in", { replace: true })
  }

  const navigateBack = () => {
    // window.history.back()
    navigate("/")
  }

  const onLinkSetActive = () => {
    if (isMobile) {
      setMobileActive(false)
    }
  }

  const renderPortalMenu = () => {
    if (isGHP) {
      if (user) {
        return (
          <>
            <MenuLink>
              <Link to={"/profiles"} className="mouse-link">
                Growth Hackers
              </Link>
            </MenuLink>
            <MenuLink>
              <Link to={"/profile"} className="mouse-link">
                My Profile
              </Link>
            </MenuLink>
            <MenuLink>
              <a onClick={handleSignOut} className="mouse-link">
                Sign Out
              </a>
            </MenuLink>
          </>
        )
      } else {
        return (
          <>
            <MenuLink>
              <Link to={"/sign-up"} className="mouse-link">
                Sign Up
              </Link>
            </MenuLink>
            <MenuLink>
              <Link to={"/sign-in"} className="mouse-link">
                Sign In
              </Link>
            </MenuLink>
          </>
        )
      }
    } else {
      if (user) {
        return (
          <>
            <MenuLink className="small">
              <Link to={"/leaderboard"} className="mouse-link">
                GROWTH HACKER PORTAL
              </Link>
            </MenuLink>
            <MenuLink className="small">
              <Link to={"/bee-123"} className="mouse-link">
                BEE123
              </Link>
            </MenuLink>
          </>
        )
      } else {
        return (
          <>
            <MenuLink className="small">
              <Link to={"/sign-up"} className="mouse-link">
                GROWTH HACKER PORTAL
              </Link>
            </MenuLink>
            <MenuLink className="small">
              <Link to={"/bee-123"} className="mouse-link">
                BEE123
              </Link>
            </MenuLink>
          </>
        )
      }
    }
  }

  const renderMenuItems = () => {
    if (items) {
      return items.map(item => {
        if (item.scrollLink) {
          return (
            <MenuLink key={item.name}>
              <ScrollLink
                activeClass="active"
                to={item.name}
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                onSetActive={onLinkSetActive}
                className="mouse-link"
              >
                {item.name}
              </ScrollLink>
            </MenuLink>
          )
        } else {
          return (
            <MenuLink key={item.name}>
              <Link to={item.path} className="mouse-link">
                {item.name}
              </Link>
            </MenuLink>
          )
        }
      })
    }
  }

  return (
    <>
      <ScrollArea
        topOffset={topOffset}
        bottomOffset={bottomOffset}
        mobileActive={mobileActive}
      >
        <Menu mobileActive={mobileActive}>
          {items && renderMenuItems()}
          {back && (
            <MenuLink>
              <Link to="/" className="mouse-link">
                Home
              </Link>
            </MenuLink>
          )}
          {renderPortalMenu()}
        </Menu>
      </ScrollArea>
      <MenuIcon onClick={() => setMobileActive(!mobileActive)}>
        <svg width="24" height="24">
          <path
            d="M24 18v1h-24v-1h24zm0-6v1h-24v-1h24zm0-6v1h-24v-1h24z"
            fill="#1040e2"
          />
          <path d="M24 19h-24v-1h24v1zm0-6h-24v-1h24v1zm0-6h-24v-1h24v1z" />
        </svg>
      </MenuIcon>
    </>
  )
}

export default Navbar
