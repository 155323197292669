/**
 * @flow
 */
import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import media from "../../styles/media"
import Label from "../elements/label"

import placeholder from "../../images/icons/profile.svg"

type Props = {
  slug: string,
  name: string,
  img: string,
  votes: number,
  imgId: string,
}

const Container = styled.div`
  .picture {
    display: flex;
    justify-content: center;
    img {
      object-fit: cover;
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
  }
  .name {
    text-align: center;
    margin-top: -20px;
  }
  .vote-panel {
    display: flex;
    justify-content: center;
    a,
    span {
      font-family: "AvenirLTStdBlack";
      display: inline-block;
      position: relative;
      font-size: 0.8rem;
      margin: 0.25rem 0.5rem;
      padding: 0.15rem 0.5rem;
      z-index: 1;
      i {
        color: var(--carnation);
      }
      &:after {
        content: "";
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.75);
      }
    }
  }
  ${media.tablet`
    .name {}
    .picture {}
    .vote-panel {}
  `}
`

const Preview = ({ slug, name, votes, imgId }: Props) => {
  const onImgError = e => {
    const target = e.target
    target.src = `${placeholder}`
  }

  return (
    <Container>
      <div className="picture">
        <img
          src={encodeURI(
            `${process.env.S3_BUCKET_BASE_URL}${imgId}/${slug}.jpg`
          )}
          alt={`Sound Idea Sessions | ${name}`}
          onError={onImgError}
        />
      </div>
      <div className="name">
        <Label
          fontSize={1}
          fontScaled
          outerRotate={-1}
          innerRotate={0}
          value={name}
          style={{ color: "rgba(255, 255, 255, 1)" }}
        />
      </div>
      <div className="vote-panel">
        <span>
          <i>&#x272A;</i> {votes}
        </span>
        <Link to={`/profiles/${slug}/`} className="mouse-link">
          INFO &#8594;
        </Link>
      </div>
    </Container>
  )
}

export default Preview
